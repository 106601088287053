import { useThemeContext } from 'contexts/theme';
import { useUserContext } from 'contexts';
import Image from 'next/image';
import ExprealtyCaIcon from 'components/icon/exprealty-ca-icon';
import ZoocasaIcon from 'components/icon/zoocasa-icon';
import ZoocasaUsaIcon from 'components/icon/zoocasa-usa-icon';
import { ThemeNames } from 'types/themes';
import { CountryCodeList } from 'types/countries';
import ExpInServiceIcon from 'components//icon/exp-in-service-icon';

interface Props {
  className?: string;
  onClick?: () => void;
}

export default function ThemedIcon({ className, onClick }: Props) {
  const { siteLocation } = useUserContext();
  const isUsSite = siteLocation === CountryCodeList.UNITED_STATES;
  const { themeName } = useThemeContext();

  switch (themeName) {
  case ThemeNames.EXP_IN_SERVICE:
    return <ExpInServiceIcon className={className} onClick={onClick} />;
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return <ExprealtyCaIcon className={className} onClick={onClick} />;
  case ThemeNames.EXP_COMMERCIAL:
    return <Image src={'/next/assets/images/exp-commercial/eXp_Commercial_logo_black.png'} width={115} height={60} alt="Exp Commercial Icon" className={className} onClick={onClick} />;
  default:
    return isUsSite
      ? <ZoocasaUsaIcon className={className} onClick={onClick} />
      : <ZoocasaIcon className={className} onClick={onClick} />;
  }
}
