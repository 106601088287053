import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ZoocasaUsaIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} width="1023" height="110" viewBox="0 0 1023 110" fill="none" xmlns="http://www.w3.org/2000/svg" transform="scale(1.17) translate(13)">
      <path d="M1016.94 0C1013.66 0 1011 2.50888 1011 5.59939C1011 8.68991 1013.66 11.1988 1016.94 11.1988C1020.22 11.1988 1022.88 8.68991 1022.88 5.59939C1022.88 2.50888 1020.22 0 1016.94 0ZM1016.94 10.1744C1014.26 10.1744 1012.09 8.12563 1012.09 5.59939C1012.09 3.07316 1014.26 1.02439 1016.94 1.02439C1019.62 1.02439 1021.8 3.07316 1021.8 5.59939C1021.8 8.12563 1019.62 10.1744 1016.94 10.1744Z" fill="white"/>
      <path d="M1017.06 6.34475H1016.04V8.49769H1014.75V2.61182H1017.07C1017.81 2.61182 1018.38 2.76808 1018.78 3.0806C1019.17 3.39313 1019.38 3.82719 1019.38 4.39147C1019.38 4.79081 1019.28 5.12937 1019.1 5.39849C1018.91 5.66761 1018.64 5.88464 1018.26 6.0409L1019.61 8.4456V8.50637H1018.23L1017.06 6.35343V6.34475ZM1016.04 5.36377H1017.08C1017.4 5.36377 1017.65 5.28564 1017.84 5.12937C1018.02 4.97311 1018.1 4.76476 1018.1 4.48696C1018.1 4.20916 1018.02 3.99213 1017.86 3.83587C1017.69 3.67961 1017.43 3.60148 1017.08 3.60148H1016.05V5.37245L1016.04 5.36377Z" fill="white"/>
      <path d="M70.6613 82.7076V97.4923H5.2193V93.1293L45.3328 32.293H10.4304V17.5078H70.5401V21.8709L30.3054 82.7076H70.6613Z" fill="white"/>
      <path d="M179.033 57.5003C179.033 81.0111 159.886 98.9464 136.375 98.9464C112.864 98.9464 93.959 81.011 93.959 57.5003C93.959 33.99 112.864 16.0538 136.375 16.0538C159.886 16.0538 179.033 33.99 179.033 57.5003ZM110.804 57.5003C110.804 73.0125 121.59 83.7979 136.496 83.7979C151.16 83.7979 162.188 73.0125 162.188 57.5003C162.188 42.1094 151.16 31.2022 136.496 31.2022C121.59 31.2022 110.804 42.1094 110.804 57.5003Z" fill="white"/>
      <path d="M293.135 57.5003C293.135 81.0111 273.987 98.9464 250.476 98.9464C226.965 98.9464 208.06 81.011 208.06 57.5003C208.06 33.99 226.965 16.0538 250.476 16.0538C273.987 16.0538 293.135 33.99 293.135 57.5003ZM224.905 57.5003C224.905 73.0125 235.691 83.7979 250.597 83.7979C265.261 83.7979 276.289 73.0125 276.289 57.5003C276.289 42.1094 265.261 31.2022 250.597 31.2022C235.691 31.2022 224.905 42.1094 224.905 57.5003Z" fill="white"/>
      <path d="M397.04 35.929L384.678 44.6542C379.346 36.171 371.833 31.2022 361.774 31.2022C346.868 31.2022 335.961 42.1094 335.961 57.6216C335.961 73.0125 346.868 83.798 361.774 83.798C371.833 83.798 379.346 78.8296 384.678 70.3461L397.04 79.0712C390.132 91.3115 377.165 98.9464 361.653 98.9464C337.9 98.9464 319.115 81.011 319.115 57.5003C319.115 33.99 337.9 16.0538 361.774 16.0538C377.165 16.0538 390.011 23.6886 397.04 35.929Z" fill="white"/>
      <path d="M573.455 31.2022L562.306 39.807C558.428 33.99 553.58 30.5965 547.036 30.5965C541.704 30.5965 537.099 33.02 537.099 38.3526C537.099 42.9576 540.613 44.8967 546.43 47.5631L555.519 51.562C568.123 57.137 575.273 63.5599 575.273 74.9515C575.273 89.8575 563.154 98.9464 547.279 98.9464C533.463 98.9464 522.313 91.9177 516.375 81.2527L527.646 72.77C532.009 78.8296 537.704 84.4041 547.279 84.4041C554.307 84.4041 558.67 81.1314 558.67 75.5569C558.67 70.346 554.792 68.5282 548.49 65.6201L539.886 61.7421C528.494 56.5308 520.496 49.9867 520.496 38.7163C520.496 24.9007 532.009 16.0538 547.036 16.0538C559.761 16.0538 568.729 22.4766 573.455 31.2022Z" fill="white"/>
      <path d="M201.937 18.9673C201.842 21.1237 200.914 23.1592 199.35 24.6464C197.785 26.1336 195.705 26.9569 193.547 26.9435C189.3 26.9435 185.156 23.2143 185.156 18.9673C185.228 16.7934 186.145 14.7332 187.712 13.2244C189.278 11.7157 191.372 10.8774 193.547 10.8876C194.631 10.8568 195.71 11.0425 196.722 11.4338C197.733 11.8251 198.657 12.4141 199.438 13.1664C200.219 13.9186 200.842 14.8191 201.272 15.8151C201.701 16.8112 201.927 17.8827 201.937 18.9673Z" fill="white"/>
      <path d="M459.508 16.5359C458.537 16.2285 457.527 16.0661 456.509 16.0539C455.539 16.0575 454.575 16.2059 453.649 16.494L418.092 97.4923H435.664L440.148 87.0698H472.87L477.354 97.4923H494.926L459.508 16.5359ZM445.965 73.6179L456.509 46.7145L467.052 73.618L445.965 73.6179Z" fill="white"/>
      <path d="M638.453 16.5359C637.482 16.2285 636.472 16.0661 635.454 16.0539C634.484 16.0575 633.52 16.2059 632.594 16.494L597.037 97.4923H614.609L619.093 87.0698H651.815L656.298 97.4923H673.871L638.453 16.5359ZM624.91 73.6179L635.454 46.7145L645.997 73.618L624.91 73.6179Z" fill="white"/>
      <path d="M740.475 78.1253L745.714 63.8631L745.789 68.0741L733.983 39.0234H739.447L748.27 61.4569H746.666L755.088 39.0234H760.402L745.463 78.1253H740.475Z" fill="white"/>
      <path d="M720.689 66.846C718.099 66.846 715.926 66.2193 714.172 64.9661C712.417 63.6961 711.089 61.9833 710.186 59.8277C709.284 57.6721 708.833 55.2407 708.833 52.5337C708.833 49.8266 709.276 47.3953 710.161 45.2397C711.064 43.0841 712.384 41.388 714.122 40.1514C715.876 38.8982 718.032 38.2715 720.588 38.2715C723.128 38.2715 725.317 38.8982 727.156 40.1514C729.01 41.388 730.439 43.0841 731.442 45.2397C732.444 47.3786 732.946 49.8099 732.946 52.5337C732.946 55.2407 732.444 57.6804 731.442 59.8528C730.456 62.0084 729.044 63.7128 727.206 64.9661C725.384 66.2193 723.212 66.846 720.689 66.846ZM707.956 66.094V30H713.219V46.6183H712.618V66.094H707.956ZM719.962 62.1086C721.633 62.1086 723.011 61.6825 724.098 60.8303C725.2 59.9781 726.019 58.8334 726.554 57.3964C727.105 55.9426 727.381 54.3217 727.381 52.5337C727.381 50.7624 727.105 49.1582 726.554 47.7212C726.019 46.2841 725.192 45.1394 724.073 44.2872C722.953 43.435 721.524 43.0089 719.786 43.0089C718.149 43.0089 716.795 43.4099 715.726 44.212C714.673 45.0141 713.888 46.1337 713.37 47.5708C712.868 49.0078 712.618 50.6621 712.618 52.5337C712.618 54.4052 712.868 56.0595 713.37 57.4966C713.871 58.9337 714.665 60.0616 715.751 60.8804C716.837 61.6992 718.241 62.1086 719.962 62.1086Z" fill="white"/>
      <path d="M809.045 53.0093C809.612 57.816 811.596 61.547 814.996 64.2174C818.397 66.8878 822.502 68.2231 827.319 68.2231C831.57 68.2231 835.133 67.399 838.015 65.751C840.889 64.1106 843.415 62.0353 845.593 59.548L857.771 68.2231C853.803 72.8543 849.366 76.1428 844.46 78.096C839.545 80.0492 834.404 81.0335 829.02 81.0335C823.919 81.0335 819.101 80.2323 814.575 78.6301C810.041 77.0278 806.123 74.7618 802.819 71.8243C799.516 68.8868 796.893 65.3543 794.957 61.2189C793.022 57.0836 792.051 52.4752 792.051 47.4014C792.051 42.3276 793.014 37.7268 794.957 33.5839C796.893 29.4485 799.508 25.9083 802.819 22.9709C806.123 20.0334 810.041 17.7674 814.575 16.1651C819.11 14.5628 823.927 13.7617 829.02 13.7617C833.74 13.7617 838.056 14.54 841.974 16.0964C845.893 17.6529 849.245 19.8961 852.03 22.8335C854.815 25.771 856.985 29.3951 858.548 33.7136C860.102 38.032 860.888 42.9914 860.888 48.5916V52.994H809.045V53.0093ZM843.885 41C843.788 36.1933 842.233 32.4623 839.213 29.7919C836.193 27.1215 831.991 25.7862 826.607 25.7862C821.506 25.7862 817.474 27.1672 814.494 29.9216C811.523 32.6836 809.701 36.3764 809.045 41H843.885Z" fill="white"/>
      <path d="M943.304 15.373H959.449V24.9789H959.732C962.185 20.9733 965.489 18.1045 969.642 16.3725C973.796 14.6405 978.281 13.7708 983.099 13.7708C988.289 13.7708 992.96 14.6405 997.122 16.3725C1001.28 18.1045 1004.81 20.4926 1007.74 23.514C1010.67 26.543 1012.89 30.0985 1014.4 34.188C1015.91 38.2852 1016.67 42.6876 1016.67 47.4028C1016.67 52.118 1015.87 56.528 1014.26 60.6176C1012.66 64.7147 1010.42 68.2702 1007.53 71.2916C1004.65 74.3206 1001.19 76.7011 997.122 78.4331C993.066 80.165 988.629 81.0348 983.811 81.0348C980.694 81.0348 977.885 80.722 975.383 80.104C972.881 79.4784 970.687 78.6849 968.8 77.7006C966.914 76.724 965.303 75.6787 963.983 74.5648C962.663 73.4508 961.578 72.3598 960.728 71.2916H960.307V109.86H943.312V15.373H943.304ZM999.673 47.4028C999.673 44.9155 999.227 42.5121 998.329 40.2003C997.43 37.8885 996.11 35.8437 994.361 34.0583C992.612 32.2806 990.515 30.8538 988.062 29.7856C985.609 28.7175 982.824 28.1834 979.706 28.1834C976.589 28.1834 973.804 28.7175 971.351 29.7856C968.897 30.8538 966.792 32.2806 965.052 34.0583C963.303 35.8437 961.983 37.8885 961.084 40.2003C960.186 42.5121 959.74 44.9155 959.74 47.4028C959.74 49.8901 960.186 52.3011 961.084 54.6053C961.983 56.9248 963.303 58.9695 965.052 60.7473C966.8 62.525 968.897 63.9518 971.351 65.0199C973.804 66.0881 976.589 66.6222 979.706 66.6222C982.824 66.6222 985.609 66.0881 988.062 65.0199C990.515 63.9518 992.612 62.5326 994.361 60.7473C996.11 58.9695 997.43 56.9248 998.329 54.6053C999.227 52.2935 999.673 49.8901 999.673 47.4028Z" fill="white"/>
      <path d="M908.846 47.4038L934.772 79.4336H914.101L898.491 60.1608L882.921 79.4336H862.243L926.529 0H947.2L908.846 47.4038Z" fill="white"/>
      <path d="M882.921 15.3757H862.243L883.164 41.2559L893.487 28.4684L882.921 15.3757Z" fill="white"/>
      <path d="M807.764 91.432C807.764 91.1878 807.975 90.9666 808.258 90.9666H815.707C818.832 90.9666 821.391 93.3242 821.391 96.2464C821.391 98.5048 819.804 100.344 817.537 101.206L821.108 107.439C821.294 107.76 821.108 108.149 820.662 108.149H817.926C817.691 108.149 817.561 108.027 817.505 107.928L814.039 101.419H811.149V107.683C811.149 107.928 810.914 108.149 810.655 108.149H808.258C807.975 108.149 807.764 107.928 807.764 107.683V91.432ZM815.424 98.7947C816.808 98.7947 818.007 97.6655 818.007 96.2921C818.007 94.9874 816.808 93.9116 815.424 93.9116H811.181V98.7947H815.424Z" fill="white"/>
      <path d="M832.374 91.432C832.374 91.1878 832.584 90.9666 832.868 90.9666H843.474C843.758 90.9666 843.968 91.1878 843.968 91.432V93.4462C843.968 93.6904 843.758 93.9116 843.474 93.9116H835.758V97.9402H842.195C842.454 97.9402 842.689 98.1614 842.689 98.4056V100.443C842.689 100.71 842.454 100.908 842.195 100.908H835.758V105.204H843.474C843.758 105.204 843.968 105.425 843.968 105.669V107.683C843.968 107.928 843.758 108.149 843.474 108.149H832.868C832.584 108.149 832.374 107.928 832.374 107.683V91.432Z" fill="white"/>
      <path d="M851.082 107.517L859.186 90.9909C859.267 90.846 859.397 90.7239 859.632 90.7239H859.891C860.15 90.7239 860.255 90.846 860.336 90.9909L868.36 107.517C868.514 107.837 868.311 108.158 867.915 108.158H865.647C865.259 108.158 865.073 108.013 864.895 107.67L863.615 105.014H855.826L854.547 107.67C854.442 107.914 854.207 108.158 853.794 108.158H851.527C851.138 108.158 850.928 107.837 851.082 107.517ZM862.336 102.291L859.729 96.8887H859.648L857.098 102.291H862.336Z" fill="white"/>
      <path d="M875.942 91.432C875.942 91.1878 876.153 90.9666 876.436 90.9666H878.833C879.092 90.9666 879.327 91.1878 879.327 91.432V105.204H885.974C886.257 105.204 886.468 105.425 886.468 105.669V107.683C886.468 107.928 886.257 108.149 885.974 108.149H876.436C876.153 108.149 875.942 107.928 875.942 107.683V91.432Z" fill="white"/>
      <path d="M897.791 93.9116H893.808C893.516 93.9116 893.314 93.6904 893.314 93.4462V91.432C893.314 91.1878 893.524 90.9666 893.808 90.9666H905.192C905.475 90.9666 905.685 91.1878 905.685 91.432V93.4462C905.685 93.6904 905.475 93.9116 905.192 93.9116H901.208V107.683C901.208 107.928 900.973 108.149 900.714 108.149H898.293C898.034 108.149 897.799 107.928 897.799 107.683V93.9116H897.791Z" fill="white"/>
      <path d="M920.043 99.8781L914.1 91.6761C913.89 91.3557 914.1 90.9666 914.513 90.9666H917.169C917.38 90.9666 917.509 91.0886 917.59 91.1878L921.76 96.8338L925.93 91.1878C926.01 91.0886 926.14 90.9666 926.351 90.9666H929.031C929.452 90.9666 929.654 91.3633 929.452 91.6761L923.436 99.8552V107.691C923.436 107.935 923.201 108.156 922.942 108.156H920.545C920.262 108.156 920.051 107.935 920.051 107.691V99.8858L920.043 99.8781Z" fill="white"/>
    </svg>

  );
};

export default ZoocasaUsaIcon;
