export type ScrollPosition = {
  /** 
   * The read-only number of pixels by which the document is currently scrolled vertically.
   * 
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/Window/scrollY)
   */
  readonly position: number;

  /**
   * A string containing the path of the URL for the location. If there is no path, pathname 
   * will be empty: otherwise, pathname contains an initial '/' followed by the path of 
   * the URL, not including the query string or fragment.
   * 
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/Location/pathname)
   */
  readonly pathname: string;
}

/**
 * Creates a new {@link ScrollPosition} object.
 * 
 * @param position The number of pixels by which the document is currently scrolled vertically.
 * @param pathname The path of the URL for the location.
 * @returns A new {@link ScrollPosition} object.
 */
export function createScrollPosition(position: number, pathname: string): ScrollPosition {
  return { position, pathname };
}

/**
 * Checks if an object is a {@link ScrollPosition} object.
 * 
 * @param obj The object to check.
 * @returns True if the object is a {@link ScrollPosition} object, false otherwise.
 */
export function isScrollPosition(obj: unknown): obj is ScrollPosition {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'position' in obj &&
    'pathname' in obj &&
    typeof (obj as ScrollPosition).position === 'number' &&
    typeof (obj as ScrollPosition).pathname === 'string'
  );
}

/**
 * Parses a JSON string into a {@link ScrollPosition} object.
 * 
 * @param json The JSON string to parse.
 * @returns A {@link ScrollPosition} object if the JSON string is valid, null otherwise.
 */
export function fromJSON(json: string): ScrollPosition | null {
  try {
    const parsed = JSON.parse(json);
    return isScrollPosition(parsed) ? parsed : null;
  } catch {
    return null;
  }
}

/**
 * Converts a {@link ScrollPosition} object to a JSON string.
 * 
 * @param scrollPosition The {@link ScrollPosition} object to convert.
 * @returns A JSON string representation of the {@link ScrollPosition} object.
 */
export function toJSON(scrollPosition: ScrollPosition): string {
  return JSON.stringify(scrollPosition);
}
